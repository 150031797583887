<template>
  <div class="component">
    <div class="head">
      <div class="title">{{ $t("button.addTicket") }}</div>
      <div class="subtitle">
        <router-link
            :to="{ name: 'event-tickets', params:{ eventId: this.$route.params.eventId } }">
          <span>{{ $t('events.tickets.create.viewAllTickets') }}</span>
        </router-link>
      </div>
    </div>
    <div class="main">
      <div v-if="!finished" class="add-ticket-form">
        <mu-container>
          <!--<mu-text-field
              :label="$t('events.tickets.create.ticketTitle')"
              v-model="formData.title"
              placeholder=""
              :error-text="$t(errors.title)"
              full-width
              label-float
          ></mu-text-field>-->
          <upload-image @imageData="setImageData"></upload-image>
          <!-- TODO: <upload-ticket-file @fileData="setTicketFileData"></upload-ticket-file> -->
        </mu-container>
      </div>
      <footer v-if="!finished" class="footer-nav">
        <mu-button @click="saveTicket" class="button md-raised">{{ $t("button.next") }}</mu-button>
      </footer>

      <div v-if="failed" class="failed-message">
        <p>{{ $t("events.tickets.create.failed") }}</p>
      </div>

      <div v-if="finished" class="success-message">
        <p>{{ $t("events.tickets.create.success") }}</p>
        <p>
          <router-link
              :to="{ name: 'view-ticket', params:{ eventId: this.$route.params.eventId, id_ticket: this.addedTicketId } }"
          >
            {{ $t('events.tickets.create.viewAddedTicket') }}
          </router-link><br />
          <router-link
              :to="{ name: 'event-tickets', params:{ eventId: this.$route.params.eventId } }"
          >
            {{ $t('events.tickets.create.viewAllTickets') }}
          </router-link><br />
          <a
              @click.prevent="resetThePage"
          >
            {{ $t('events.tickets.create.addAnotherTicket') }}
          </a><br />
        </p>
      </div>

      <div class="push"></div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions, mapState} from "vuex";
import uploadImage from "./components/uploadImages/uploadImage";

export default {
  name: 'create-ticket',
  components: {uploadImage},
  computed: {
    ...mapGetters('eventStore', ['getEventInfo']),
    ...mapState('ticketsStore',['ticketInfo'])
  },
  data() {
    return {
      errors: {
        title: '',
        file_url: '',
        data: '',
        event_id: ''
      },
      formData: {
        title: '',
        file_url: '',
        data: {},
        event_id: 0
      },
      finished: false,
      failed: false,
      failDetails: '',
      addedTicketId: 0
    }
  },
  methods: {
    ...mapActions('ticketsStore', ['createTicket']),
    ...mapActions('eventStore', ['event']),
    createTicketSuccess: function () {
      this.finished = true;
      this.failed = false;
      this.addedTicketId = this.ticketInfo.id;
    },
    createTicketFail: function () {
      this.finished = false;
      this.failed = true;
    },
    saveTicket() {
      let ticketResult = this.createTicket(this.formData);
      ticketResult.then(this.createTicketSuccess, this.createTicketFail);
    },
    useEventDates: function (eventInfo) {
      // input: 2020-05-15T12:00:00Z
      // output: 2020-05-15T12:00
      let replaceRegexp = /:\d{2}Z$/;
      if(eventInfo.date_start){
        this.formData.date_start = eventInfo.date_start.replace(replaceRegexp,'');
      }
      if(eventInfo.date_end){
        this.formData.date_end = eventInfo.date_end.replace(replaceRegexp,'');
      }
    },
    setImageData(data) {
      this.useEventDates(this.getEventInfo);
      this.formData.file_url = data.image;
    },
    resetThePage(){
      this.finished = false;
      this.failed = false;
      this.addedTicketId = 0;
    }
  },
  mounted() {
    this.formData.event_id = this.$route.params.eventId;
    this.event(this.formData.event_id);
  }
}
</script>

<style scoped lang="scss">

  .head {
    padding: 20px 0;

    & .text {
      font-size: 24px;

    }
  }

  .finished {
    font-size: 22px;
  }

  .footer-nav,
  .push {
    height: 50px;
  }

  .failed-message,
  .success-message {
    padding: 1rem;
    display: block;
  }

  .failed-message {
    background-color: #fdd;
  }

  .success-message {
    background-color: #dfd;
  }


</style>
